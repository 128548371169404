<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Trạng thái">
                <b-form-select :options="status" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group label="Thời gian bắt đầu(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
            <template #cell(url)="data">
              <a v-if="data.item.url !== '0' && data.item.url !== ''" :href="data.item.url">Tải file</a>
            </template>
            <template #cell(created_at)="data">
              {{ formatDate(data.item.created_at) }}
            </template>
            <template #cell(updated_at)="data">
              {{ formatDate(data.item.updated_at) }}
            </template>
          </b-table>

          <div class="mt-3">
            <b-pagination-nav
                v-model="currentPage"
                :link-gen="createLinkPage"
                :number-of-pages="paginate.totalPage"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Vue from "vue";
import router from "../../../../router";

Vue.use(Notifications);

const BiRepository = RepositoryFactory.get("Bi");

export default {
  mixins: [Common],
  data() {
    return {
      exportId: "export_rfm_reconcile",
      items: null,
      products: [],
      providers: [],
      process: 0,
      target: 0,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      fields: ["id", "file_name", "type", "url", "email_received", "process", "max_rows", "status", "created_at", "updated_at"],
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: null,
        type: null
      },
      status: [
        { value: "", text: "Tất cả" },
        { value: "SUCCESS", text: "SUCCESS" },
        { value: "PENDING", text: "PENDING" },
        { value: "FAILED", text: "FAILED" },
        { value: "PROCESSING", text: "PROCESSING" },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách xuất file RFM", route: "statistics" },
    ]);
  },
  methods: {
    search() {
      this.filter.page = 1;
      router.push({ path: "/tools/list-export", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList();
    },
    async getList() {
      this.$bus.$emit("show-loading", true);
      this.items = null;
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await BiRepository.getListRfm(params);

      if (response.error_code === 1) {
        this.$bus.$emit("show-loading", false);
        this.notifyAlert("error", "Có lỗi xảy ra, vui lòng thử lại");
        return;
      }

      let body = response.data;

      this.$bus.$emit("show-loading", false);
      this.items = body.data.data;
      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
  },
  created() {
    this.getList();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getList();
      }
    },
  },
};
</script>
